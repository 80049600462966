import {
  CONTACT_REQUEST_URL,
  DEFAULT_URL,
  ERROR_404_URL,
  ERROR_500_URL,
  ERROR_ACCESS_DENIED_URL,
  PRIVACY_POLICY_URL,
  TERM_OF_SERVICES_URL
} from '../core/constants/url'

const pathConfiguration = {
  error500: ERROR_500_URL,
  error404: ERROR_404_URL,
  errorAccessDenied: ERROR_ACCESS_DENIED_URL,

  default: DEFAULT_URL,
  contactRequest: CONTACT_REQUEST_URL,
  termOfServices: TERM_OF_SERVICES_URL,
  privacyPolicy: PRIVACY_POLICY_URL
}

export default pathConfiguration
