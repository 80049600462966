import Gleap from 'gleap'
import { appWithTranslation, SSRConfig } from 'next-i18next'
import type { AppProps } from 'next/app'
import Script from 'next/script'
import dynamic from 'next/dynamic'
import { Manrope as HeadingFont, Inter as InterFont } from 'next/font/google'
import localFont from 'next/font/local'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import configuration from '~/configuration'
import { IUserInformation, Maybe } from '~/core/@types/global'
import '../src/styles/globals.css'

const AppRouteLoadingIndicator = dynamic(
  () => import('~/core/ui/AppRouteLoadingIndicator'),
  {
    ssr: false
  }
)

const fontFamilySans = InterFont({
  subsets: ['latin'],
  variable: '--font-family-sans',
  fallback: ['system-ui', 'Helvetica Neue', 'Helvetica', 'Arial'],
  preload: true,
  weight: ['300', '400', '500', '600', '700', '800']
})

const fontFamilyHeading = HeadingFont({
  subsets: ['latin'],
  variable: '--font-family-heading',
  fallback: ['--font-family-sans'],
  preload: true,
  weight: ['400', '500']
})

const NotoSerif = localFont({
  src: [
    {
      path: '../public/fonts/NotoSerif-Thin.ttf',
      weight: '300',
      style: 'normal'
    },
    {
      path: '../public/fonts/NotoSerif-Regular.ttf',
      weight: '400',
      style: 'normal'
    },
    {
      path: '../public/fonts/NotoSerif-Medium.ttf',
      weight: '500',
      style: 'normal'
    },
    {
      path: '../public/fonts/NotoSerif-Bold.ttf',
      weight: '700',
      style: 'normal'
    }
  ],
  variable: '--font-notoSerif'
})

const BlinkMacSystemFont = localFont({
  src: [
    {
      path: '../public/fonts/BlinkMacSystemFont-Thin.otf',
      weight: '200',
      style: 'normal'
    },
    {
      path: '../public/fonts/BlinkMacSystemFont-Light.otf',
      weight: '300',
      style: 'normal'
    },
    {
      path: '../public/fonts/BlinkMacSystemFont-Regular.otf',
      weight: '400',
      style: 'normal'
    },
    {
      path: '../public/fonts/BlinkMacSystemFont-Medium.otf',
      weight: '500',
      style: 'normal'
    },
    {
      path: '../public/fonts/BlinkMacSystemFont-Semibold.otf',
      weight: '600',
      style: 'normal'
    },
    {
      path: '../public/fonts/BlinkMacSystemFont-Bold.otf',
      weight: '700',
      style: 'normal'
    }
  ],
  variable: '--font-blinkMacSystem'
})

interface DefaultPageProps extends SSRConfig {
  user?: Maybe<IUserInformation>
}

function App(
  props: AppProps<DefaultPageProps> & { pageProps: DefaultPageProps }
) {
  const { Component } = props
  const pageProps = props.pageProps as DefaultPageProps

  if (
    typeof window !== 'undefined' &&
    configuration.key.gleapApiKey &&
    Gleap &&
    Gleap.getInstance().initialized === false
  ) {
    Gleap.initialize(configuration.key.gleapApiKey)
  }

  return (
    <>
      <AppRouteLoadingIndicator />
      <FontFamily primaryFont={BlinkMacSystemFont.style.fontFamily} />
      <Script
        src="https://www.googletagmanager.com/gtag/js?id=GTM-MLR6F82X"
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-RE7QW8JV1G');
        `}
      </Script>
      <main className={NotoSerif.variable}>
        <Component {...pageProps} />
      </main>
    </>
  )
}

export default appWithTranslation(App)

function FontFamily({ primaryFont }: { primaryFont: string }) {
  return (
    <style jsx global>
      {`
        html {
          --font-family-sans: ${primaryFont}, ${fontFamilySans.style.fontFamily},
            'Ubuntu', 'sans-serif';

          --font-family-heading: ${primaryFont},
            ${fontFamilyHeading.style.fontFamily};
        }
      `}
    </style>
  )
}
