import {
  APP_ENVIRONMENT,
  DEFAULT_LOCALE,
  NODE_ENV
} from '../core/constants/env'
import { ICONS_SOCIAL } from '../core/constants/social'
import apiConfiguration from './api'
import keyConfiguration from './key'
import pathConfiguration from './path'
import seoConfiguration from './seo'

const configuration = {
  site: {
    locale: DEFAULT_LOCALE
  },
  path: pathConfiguration,
  api: apiConfiguration,
  key: keyConfiguration,
  seo: seoConfiguration,
  environment: APP_ENVIRONMENT,
  production: NODE_ENV === 'production',
  mailto: `support@freec.asia`,
  iconSocial: ICONS_SOCIAL
}

export default configuration
