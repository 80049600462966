export const ICONS_SOCIAL = {
  facebook: '/img/img-social/facebook.svg',
  facebook_darkmode: '/img/img-social/facebook_darkmode.svg',
  google: '/img/img-social/google.svg',
  google_darkmode: '/img/img-social/google_darkmode.svg',
  linkedin: '/img/img-social/linkedin.svg',
  linkedin_darkmode: '/img/img-social/linkedin_darkmode.svg',
  twitter_darkmode: '/img/img-social/twitter_darkmode.svg',
  product_hunt: '/img/img-social/product_hunt.svg',
  share: '/img/img-social/share.svg',
  googleplay: '/img/img-social/google_play.svg',
  googleplay_darkmode: '/img/img-social/google_play_darkmode.svg',
  appstore: '/img/img-social/app_store.svg',
  appstore_darkmode: '/img/img-social/app_store_darkmode.svg',
  hireforce_text: '/img/img-social/hireforce_logo.png',
  flag_us: '/img/icon-languages/flag-us.svg',
  flag_vn: '/img/icon-languages/flag-vn.svg'
}
