import { PUBLIC_APP_NAME } from '../core/constants/env'

const seoConfiguration = {
  500: `Error - ${PUBLIC_APP_NAME}`,
  400: `Not found - ${PUBLIC_APP_NAME}`,
  accessDenied: `Access Denied - ${PUBLIC_APP_NAME}`,
  authError: `Authentication Error - ${PUBLIC_APP_NAME}`
}

export default seoConfiguration
